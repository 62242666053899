import {createApp} from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

import App from './components/App.vue'

createApp(App)
    .use(ElementPlus)
    .mount('#app')


