<template>
  <div id="app">
    <header>
      <div class="avatar-container">
        <img :src="avatarUrl" alt="Avatar" class="avatar"/>
      </div>
      <h1>godmao233</h1>
    </header>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      avatarUrl: 'http://io.godmao.com/hub/godmao.jpg',
    };
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

#app {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}

header {
  text-align: center;
}

.avatar-container {
  margin-bottom: 20px;
}

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

h1 {
  font-size: 2em;
  color: #333;
}

h2 {
  font-size: 1.5em;
  margin-top: 20px;
  color: #555;
}

p {
  font-size: 1em;
  color: #777;
}

ul {
  list-style-type: none;
}

ul li {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

ul li:last-child {
  border-bottom: none;
}
</style>
